import React, { useState, useEffect } from 'react';
import './App.css';
import Gallery from './components/Gallery';

// shoelace
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import { SlTab, SlTabGroup } from '@shoelace-style/shoelace/dist/react';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.4.0/dist/');

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 900;
  const [selected, setSelected] = useState('CEREMONY');

  const galleries = [
    {
      title: 'GROOM+PREP',
    },
    {
      title: 'BRIDE+PREP',
    },
    {
      title: 'DETAILS',
    },
    { 
      title: 'CEREMONY',
    },
    {
      title: 'RECEPTION',
    },
    {
      title: 'FAMILY+PHOTOS',
    },
    {
      title: 'ROMANTICS',
    },
    {
      title: 'JOYFUL+VOWS',
    },
  ];

  let selectTab = (event: any) => setSelected(event.detail.name);

  return (
    <div className="App">
      {/* nav header */}
      <div className="sticky">
      <SlTabGroup onSlTabShow={selectTab}>
        {galleries.map(({ title }, i) => {
          return (
            <SlTab key={i} slot="nav" panel={title} active={selected === title}>
              {title}
            </SlTab>
          )
        })}
      </SlTabGroup>
      </div>

      {/* galleries */}
      <Gallery mobile={isMobile} title={selected} />
    </div>
  );
}

export default App;
