import React, { useState, CSSProperties } from 'react';
import './Gallery.css';
import { SlButton, SlDialog } from '@shoelace-style/shoelace/dist/react';

import data from '../data/manifest.json';
const manifest: {[index: string]:any} = data; 
const pageSize: number = 12;

interface GalleryProps {
  title: string,
  mobile: boolean,
}

function Gallery({ title, mobile }: GalleryProps) {
  const images: string[] = []
  Object.keys(manifest).map((key) => {
    if (key === title) {
      images.push(...manifest[key])
    }
  })
  images.sort();

  const [chunk, setChunk] = useState(1);

  const handleScroll = (event: any) => {
    if (event.nativeEvent?.wheelDelta <= 0) {
      setChunk(chunk+1); 
    }
  };

  return (
    <div className="container" onWheel={handleScroll} onTouchMove={() => setChunk(chunk+1)}>
      <ul className="image-gallery">
        <GalleryPage mobile={mobile} images={images} title={title} size={chunk * pageSize} />
      </ul>
    </div>
  );
}

interface GalleryPageProps {
  images: string[],
  title: string,
  size: number,
  mobile: boolean,
}

function GalleryPage({ mobile, images, title, size }: GalleryPageProps) {
    return (
    <>
      {images.map((img: string, i: number) => {
        if (i < size) {
          return (
            <ImageItem mobile={mobile} key={`${title}:${i}`} title={title} img={img} size="medium" />
          )
        }
      })}
    </>
    )
}

interface ImageItemProps {
  title: string,
  img: string,
  size: string,
  mobile: boolean,
}

const dialogStyle = {
  '--width': '99vw',
  textAlign: 'center',
  zIndex: 1000000,
  overflow: 'hidden',
};

function ImageItem({ mobile, title, img, size }: ImageItemProps) {
  const getImageURL = (img: string, size: string) => {
    return `https://cdn.tawnyandrobbie.com/${title}/${img}_${size}.jpg` 
  };

  const [open, setOpen] = useState(false);

  return (
    <>
    <li onClick={() => setOpen(true)}>
      <img src={getImageURL(img, size)}/>
    </li>
    {open &&

    <SlDialog noHeader={true} open={open} style={dialogStyle as CSSProperties} onSlAfterHide={() => setOpen(false)}>
      <div style={{textAlign: 'center' }}>
        <img id="dialog-image" src={getImageURL(img, "large")}/>
      </div>
      <SlButton slot="footer" variant="primary" target="_blank" href={`https://cdn.tawnyandrobbie.com/${title}/${img}.jpg`} download={`${img}.jpg`}>
        Download
      </SlButton>
      <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
        Close
      </SlButton>
    </SlDialog>
    }
    </>
  );
}

export default Gallery;
